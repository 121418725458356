import Pusher from 'pusher-js';
import CommonMixin from "@/common/mixins/common-mixin";

export default {
    mixins:[CommonMixin],
    data() {
        return {
            pusher: null,
            socket:{
                connected:false,
                channel: null,
            }
        }
    },
    methods: {
        initPusher() {
            if (this.pusher) {
                this.disconnectPusher();
            }
            this.pusher = new Pusher(this.app.key, {
                    wsHost: this.app.host,
                    wsPort: this.app.port,
                    wsPath: this.app.path,
                    disableStats: true,
                    forceTLS: false,
                    enabledTransports: ['ws', 'flash'],
                    scheme: 'http',
                authEndpoint:this.$organization.build_time + '/post',
                auth: {
                    headers: {
                        'X-CSRF-Token': "123456789",
                        'X-App-ID': this.app.id
                    }
                },
            });
            this.pusher.connection.bind('connected', () => {
                this.socket.connected = true;
            });
            this.pusher.connection.bind('disconnected', () => {
                this.socket.connected = false;
            });
            this.pusher.connection.bind('error', event => {
                if (event.error.data.code === 4100) {
                    console.error("Maximum connection limit exceeded!");
                    this.connected = false;
                    throw new Error("Over capacity");
                }
            });
            return this.pusher;
        },
        disconnectPusher() {
            if (this.pusher) {
                this.pusher.disconnect();
                this.pusher = null;
            }
        },
    },
    destroyed() {
        this.disconnectPusher();
    },
}
